export default function Logo() {
  return (
    <svg
      width="36"
      height="38"
      viewBox="0 0 36 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.6637 3.11C35.8647 2.8088 35.9801 2.45869 35.9976 2.09703C36.0152 1.73537 35.9343 1.37573 35.7634 1.05647C35.5926 0.73721 35.3383 0.470312 35.0277 0.284251C34.7171 0.0981901 34.3618 -5.41675e-05 33.9997 2.24055e-08H1.99901C1.63723 0.000186904 1.28228 0.0984957 0.971959 0.284456C0.661638 0.470416 0.407568 0.737063 0.236809 1.056C0.0660506 1.37493 -0.0150018 1.73421 0.00228498 2.09557C0.0195717 2.45692 0.13455 2.80682 0.334974 3.108L15.9993 26.606V34H9.99917V38H25.9995V34H19.9994V26.606L35.6637 3.11ZM17.9993 22.394L11.0692 12H24.9295L17.9993 22.394ZM30.2636 4L27.5975 8H8.40114L5.73508 4H30.2636Z"
        fill="black"
      />
    </svg>
  );
}
